import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import noop from 'lodash/noop';
import { themeGet, borders, borderRadius, color } from 'styled-system';
import { Box, Icon, Input as BaseInput, Text } from '@qga/roo-ui/components';
import { mediaQuery, cursor } from 'lib/styledSystem';

const getAlto = themeGet('colors.greys.alto');
const getBrandSecondary = themeGet('colors.brand.secondary');

const InputIcon = styled(Icon)`
  position: absolute;
  left: ${(p) => p.theme.space[3]};
  top: 50%;
  transform: translateY(-50%);
  ${cursor};
`;

const InputPrefix = styled(Text)`
  position: absolute;
  left: ${(p) => p.theme.space[3]};
  top: 50%;
  transform: translateY(-50%);
`;

const InputSuffix = styled(Text)`
  position: absolute;
  color: ${themeGet('colors.greys.dusty')};
  right: ${(p) => p.theme.space[3]};
  top: 50%;
  transform: translateY(-50%);
`;

// We need to override the readonly styles here, until roo-ui relaxes the styles they apply
const Input = styled(BaseInput)`
  ${borders};
  ${borderRadius};
  ${color};
  flex: 1;
  font-size: ${themeGet('fontSizes.base')};
  margin: 0;
  padding: ${themeGet('space.3')} ${themeGet('space.4')};
  border-color: ${(props) => (props.isHighlighted ? getBrandSecondary(props) : getAlto(props))};

  ${(props) =>
    props.hasIconOrPrefix &&
    css`
      padding-left: ${themeGet('space.10')(props)};
    `};

  ${(props) =>
    props.noBorder === 'right' &&
    css`
      border-right: none;
    `};

  ${(props) =>
    props.noBorder === 'left' &&
    css`
      border-left: none;
    `};

  ${cursor};

  ${mediaQuery.minWidth.sm} {
    border-color: ${(props) => (props.isHighlighted ? getBrandSecondary(props) : getAlto(props))};

    ${(props) =>
      props.error &&
      css`
        border-color: ${themeGet('colors.ui.error')(props)};
      `};
  }

  &[readonly] {
    ${borders};
    ${borderRadius};
    ${color};
    padding: ${themeGet('space.3')} ${themeGet('space.4')};
    border-color: ${(props) => (props.isHighlighted ? getBrandSecondary(props) : getAlto(props))};

    ${(props) =>
      props.hasIconOrPrefix &&
      css`
        padding-left: ${themeGet('space.10')(props)};
      `};

    ${(props) =>
      props.noBorder === 'right' &&
      css`
        border-right: none;
      `};

    ${(props) =>
      props.noBorder === 'left' &&
      css`
        border-left: none;
      `};

    ${mediaQuery.minWidth.sm} {
      border-color: ${(props) => (props.isHighlighted ? getBrandSecondary(props) : getAlto(props))};
    }
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${themeGet('colors.ui.error')(props)};
    `};
`;

Input.defaultProps = {
  ...BaseInput.defaultProps,
  ...borders.defaultProps,
  ...borderRadius.defaultProps,
  ...color.defaultProps,
};

const InputWithAddon = React.forwardRef(
  (
    {
      cursor = undefined,
      icon = undefined,
      isHighlighted = false,
      onBlur = noop,
      onChange = noop,
      onFocus = noop,
      prefix = undefined,
      suffix = undefined,
      readonly = false,
      value = '',
      ...rest
    },
    ref,
  ) => {
    return (
      <Box position="relative">
        {icon && <InputIcon name={icon} {...cursor} />}
        {prefix && <InputPrefix>{prefix}</InputPrefix>}
        <Input
          {...rest}
          ref={ref}
          hasIconOrPrefix={!!(icon || prefix)}
          isHighlighted={isHighlighted}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          readOnly={readonly}
          value={value}
        />
        {suffix && <InputSuffix fontSize="xs">{suffix}</InputSuffix>}
      </Box>
    );
  },
);

InputWithAddon.propTypes = {
  icon: PropTypes.string,
  cursor: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  isHighlighted: PropTypes.bool,
  readonly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

InputWithAddon.displayName = 'InputWithAddon';

export default InputWithAddon;
